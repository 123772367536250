export const UserType = {
  employee: 'employee',
  agent: 'agent',
};

export const EmployeeSubType = {
  NORMAL: 'NORMAL',
  MATRIX: 'MATRIX',
};

export const AssetType = {
  image: 'Image',
  video: 'Video',
};

export const ImageModPricing = {
  'Virtual Staging': 35,
  'Dawn To Dusk': 35,
  Photoshop: 15,
};

export const PENDING = 'PENDING';
export const ACTIVE = 'ACTIVE';

export const USER_DATE_FORMAT = 'MM-DD-YYYY';
export const USER_HUM_DATE_FORMAT = 'MMM-DD-YYYY';
export const USER_TIME_FORMAT = 'hh:mm A';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_TIME_FORMAT = 'HH:mm';

export const TASK_STATUS = { pending: 'PENDING', completed: 'COMPLETED' };

export const MEDIA_SIZE = {
  TEN_MB: 11 * 1024 * 1024, // or 10485760 in binary
  ONE_GB: 1000000000, // or 1073741824 in binary
  ONE_HUNDRED_MB: 101 * 1024 * 1024, // or 104857600 in binary
  TWO_HUNDRED_MB: 201 * 1024 * 1024, // or 209715200 in binary
};

export const IMAGE_VIEW_SIZE = {
  xsThumbnail: '?class=xsthumbnail',
};

export const PERMISSIONS = {
  EDITI_LISTING_ADDRESS: 'edit:listing_address',
  IMPORT_IMAGE_IN_LISTING: 'manage:import_images',
};

export const COLORS = {
  lime: '#00FF00',
  green: '#008000',
  olive: '#808000',
  teal: '#008080',
  aqua: '#00FFFF',
  black: '#000000',
  white: '#FFFFFF',
  red: '#FF0000',
  indigo: '#4B0082',
  purple: '#800080',
  gradientPurple: 'linear-gradient(90deg, #800080 0%, #4B0082 100%)',
  gradientLime: 'linear-gradient(90deg, #00FF00 0%, #008000 100%)',
};

export const ORDER_STATUS = {
  PENDING: 'PENDING',
  W_APPROAVAL: 'WAITING APPROVAL',
  RESCHEDULED: 'RESCHEDULED',
  ACCEPTED: 'ACCEPTED',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  DELIVERED: 'DELIVERED',
  REDO: 'REDO',
  MODIFIED: 'MODIFIED',
  UNDER_MODIFICATION: 'UNDER MODIFICATION',
  CART: 'CART',
  MODIFICATION_REQUEST: 'MODIFICATION REQUEST',
};

export const ORDER_TYPE = {
  REGULAR: 'REGULAR',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};

export const PAYMENT_METHODS = {
  CARD: 'Card',
  CASH: 'Cash',
  ZELLE: 'Zelle',
  CHECK: 'Check',
};

export const LISTING_TYPES = {
  HOME: 'Home',
  CONDO: 'Condo',
  LOT: 'Lot',
  VACATION: 'Vacation',
  WATERFRONT_HOME: 'Waterfront Home',
  WATERFRONT_CONDO: 'Waterfront Condo',
  WATERFRONT_LOT: 'Waterfront Lot',
  COMMERCIAL_PROPERTY: 'Commercial Property',
  COMMERCIAL_LAND: 'Commercial Land',
  BOAT_SLIP: 'Boat Slip',
  BOAT: 'Boat',
};

export const PARKING_TYPES = {
  GARAGE: 'Garage',
  CARPORT: 'Carport',
  NONE: 'None',
};

export const ORDER_LISTING_STATUS = {
  FOR_SALE: 'For Sale',
  FOR_RENT: 'For Rent',
  RENTAL: 'Rental',
  BUILDER: 'Builder',
  HOME: 'Home',
};

export const LISTING_STATUS = {
  FOR_SALE: 'For Sale',
  FOR_RENT: 'For Rent',
  PENDING: 'Pending',
  SOLD: 'Sold',
  RENTAL: 'Rental',
  BUILDER: 'Builder',
  HOME: 'Home',
};

export const MEDIA_SELECTION_TYPE = {
  IMAGE: 'MediaImage',
  VIDEO: 'MediaVideo',
  IMAGE_AND_VIDEO: 'IMAGE_AND_VIDEO',
};

export const CARD_PROCESSING_FEE = 3.75;
